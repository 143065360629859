<template>
  <div class="Room">
    <RoomList :refresh="refresh" />
    <div class="page" v-loading="loading">
      <div class="header">
        <span class="title">{{ room.title }}</span>
        <i class="el-icon-edit edit" @click="edit"></i>

        <el-popconfirm
          title="確認結束直播？"
          v-if="room.status"
          @confirm="changeStatus"
        >
          <el-button type="text" slot="reference" class="right">
            結束直播
          </el-button>
        </el-popconfirm>
        <el-button type="text" @click="changeStatus" class="right" v-else>
          開始直播
        </el-button>
      </div>
      <div class="container">
        <el-scrollbar class="scoller">
          <div class="comments">
            <div class="comment" v-for="(comment, key) in comments" :key="key">
              <el-avatar
                shape="square"
                :size="'large'"
                :src="'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'"
              ></el-avatar>
              <div class="desc">
                <div class="name">
                  {{ comment.nick_name }}
                  <span class="time">{{ comment.time }}</span>
                </div>
                <div class="content">{{ comment.content }}</div>
                <div class="reply" v-if="comment.reply">
                  自動回復：{{ comment.reply }}
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import RoomList from "./List";

let socket, pool;

export default {
  name: "RoomCreator",

  components: {
    RoomList,
  },

  data() {
    return {
      id: null,
      room: {},
      loading: true,
      comments: [],
      refresh: "",
    };
  },

  watch: {
    "$route.params.id"() {
      this.init();
    },
  },

  created() {
    this.init();
  },

  destroyed() {
    socket.close();
    socket = null;
  },

  methods: {
    init() {
      this.id = this.$route.params.id;
      this.initSocket();
      this.getRoom();
    },

    edit() {
      this.$router.push("/room/edit/" + this.room.id);
    },

    async getRoom() {
      this.loading = true;

      const result = await this.$fetch("room/" + this.id);
      if (!result.isOk) {
        this.loading = false;
        this.$message.error(result.message);
        return;
      }

      this.room = result.data;
      await this.getRoomComment();

      this.loading = false;
    },

    async getRoomComment() {
      const result = await this.$fetch("room/" + this.id + "/comment");
      this.comments = result.data;
    },

    initSocket() {
      let self = this;

      if (socket && socket.close) {
        socket.close();
        socket = null;
      }

      socket = new WebSocket("wss://api.hundredkitstudio.com/ws");

      socket.addEventListener("message", function (event) {
        let message = JSON.parse(event.data);

        if (message.room_id != self.id) {
          return;
        }

        self.comments.push(message);
        self.comments = self.comments.slice(-100);
      });

      socket.addEventListener("close", function () {
        clearInterval(pool);
      });

      pool = setInterval(function () {
        socket.send("pool");
      }, 5000);
    },

    async changeStatus() {
      const result = await this.$fetch("room/" + this.room.id, {
        method: "PATCH",
        body: {
          status: this.room.status ? 0 : 1,
        },
      });

      if (result.error) {
        this.$message.error(result.message);
        return;
      }

      this.$message.success("操作成功");
      this.init();

      this.refresh = Math.random().toString();
    },
  },
};
</script>
<style scoped lang="less">
.Room {
  /deep/.el-scrollbar {
    height: 100%;

    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  .edit {
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
  }

  .comments {
    padding: 10px 20px;
  }

  .comment {
    margin: 10px 0 30px 0;
    display: flex;
    align-items: flex-start;
  }

  .desc {
    margin: 0 0 0 10px;
  }

  .name {
    font-size: 12px;
    color: #a1a1a1;
  }

  .time {
    margin-left: 5px;
    padding: 2px 5px;
    display: inline-block;
    color: #fff;
    background: #bbb;
    border-radius: 3px;
  }

  .content {
    margin: 5px 0 0 0;
    padding: 5px 10px;
    max-width: 330px;
    border: 1px #ddd solid;
    border-radius: 3px;
    display: inline-block;
    position: relative;

    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-right-color: #ddd;
      top: 8px;
      left: -11px;
    }

    &::after {
      border-right-color: #fff;
      left: -10px;
    }
  }

  .reply {
    margin: 4px 0 0 0;
    padding: 4px 8px;
    border-radius: 3px;
    background: #eee;
    font-size: 12px;
  }

  .right {
    float: right;
  }
}
</style>